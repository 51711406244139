import { Component, Input } from '@angular/core';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-sidenav-icon-button',
  standalone: true,
  imports: [ MatButtonModule, MatIconButton, MatIconModule, RouterModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './sidenav-icon-button.component.html',
  styleUrl: './sidenav-icon-button.component.scss'
})
export class SidenavIconButtonComponent {
  @Input() label: string = '';
  @Input() iconId: string = '';
  @Input() routerLink: string = '';



}
