import { Component, Inject } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ExtendedLogoComponent } from '../../components/extended-logo/extended-logo.component';
import { SquareLogoComponent } from '../../components/square-logo/square-logo.component';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LoginDto, LoginResponseDto } from '../../dto/LoginDto';
import { ENVIRONMENT } from '../../app.component';
import { ApiResponse, MessageDialogBoxComponent } from '../../components/message-dialog-box/message-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { TokenValidationService } from '../../services/AuthorizationService/token-validation.service';
import { MatIconModule } from '@angular/material/icon';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';

@Component({
  selector: 'app-login-view',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    SquareLogoComponent,
    ExtendedLogoComponent,
    RouterModule,
    RouterOutlet,
    RouterLink,
    NgIf,
    MatIconModule
  ],
  templateUrl: './login-view.component.html',
  styleUrl: './login-view.component.scss',
})
export class LoginViewComponent {

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(ENVIRONMENT) public envConfig: any,
    public dialog: MatDialog,
    private authService: TokenValidationService,
    private cookieServiceManager: CookieManagerService
  ) { }

  hidePassword = true;
  hidePasswordEvent(event: MouseEvent) {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  usernameFormControl = new FormControl('', [
    Validators.required,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  loginButtonDisabled = false;

  loginActionButton() {
    if (!this.usernameFormControl.valid || !this.passwordFormControl.valid) {
      this.usernameFormControl.markAsTouched();
      this.passwordFormControl.markAsTouched();
      this.loginButtonDisabled = true;
    } else {

      const serviceRoute: string = '/authentication/login';
      let user = new LoginDto(
        this.usernameFormControl.value ?? '',
        this.passwordFormControl.value ?? '',
      );
      this.http.post<ApiResponse<LoginResponseDto>>(
        `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
        user, { observe: 'response' }
      ).subscribe({
        next: (response) => {
          let token = response.body?.data.token
          this.cookieServiceManager.setAuthToken(token ?? '')
          window.location.reload();
          // this.router.navigate(['/dashboard'])
        },
        error: (error) => {
          this.dialog.open(MessageDialogBoxComponent, {
            data: {
              httpResponseBase: error,
            }
          });
        }
      });

    }
  }
}
