<div class="main">
    <div class="main-header">
        <p class="mat-headline-3">Atualização de dados de cliente</p>
    </div>
    <div class="main-content">
        <div class="column form">
            <p class="mat-headline-4">Identificação</p>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>CNPJ</mat-label>
                <input matInput [formControl]="companyCnpjFormControl" placeholder="CNPJ" readonly>
                @if (companyCnpjFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>CNPJ do empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Razão Social</mat-label>
                <input matInput [formControl]="companyLegalNameFormControl" placeholder="Razão social">
                @if (companyLegalNameFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Razão social do empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Nome da empresa</mat-label>
                <input matInput [formControl]="companyNameFormControl" placeholder="Nome">
                @if (companyNameFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Nome da empresa</mat-hint>
            </mat-form-field>

        </div>
        <div class="column form">
            <p class="mat-headline-4">Endereço</p>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>CEP</mat-label>
                <input matInput placeholder="CEP" [formControl]="companyZipCodeFormControl">
                <mat-hint>CEP da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>Cidade</mat-label>
                <input matInput placeholder="Cidade" [formControl]="companyCityNameFormControl" readonly>
                <mat-hint>Cidade da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>Estado</mat-label>
                <input matInput placeholder="Estado" [formControl]="companyProvinceNameFormControl" readonly>
                <mat-hint>Estado da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>Sigla Estado</mat-label>
                <input matInput placeholder="Sigla Estado" [formControl]="companyProvinceAcronymFormControl" readonly>
                <mat-hint>Sigla da estado da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>País</mat-label>
                <input matInput placeholder="País" [formControl]="companyCountryNameFormControl" readonly>
                <mat-hint>País da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>Sigla País</mat-label>
                <input matInput placeholder="Sigla País" [formControl]="companyCountryAcronymFormControl" readonly>
                <mat-hint>Sigla da País da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco" style="width: 73%; padding-right: 2%;">
                <mat-label>Rua</mat-label>
                <input matInput placeholder="Rua" [formControl]="companyStreetFormControl">
                <mat-hint>Rua da empresa</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco" style="width: 25%;">
                <mat-label>Número</mat-label>
                <input matInput placeholder="Número" [formControl]="companyNumbeFormControl">
                <mat-hint>Número </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-endereco">
                <mat-label>Complemento</mat-label>
                <input matInput placeholder="Complemento" [formControl]="companyComplementFormControl">
                <mat-hint>Complemento da empresa</mat-hint>
            </mat-form-field>
        </div>

    </div>
    <div class="main-footer">
        <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
            (click)="updateClientActionButton()">Atualizar</button>
        <button mat-flat-button class="footer-cancel-button" color="primary"
            [routerLink]="'/companies/list'">Cancelar</button>
    </div>
</div>