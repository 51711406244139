export class LoginDto {
  username!: string;
  password!: string;
  constructor(
    cpf: string,
    password: string,
  ) {
    this.username = cpf;
    this.password = password;
  }
}

export class LoginResponseDto {
  token?: string;
}
