import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-generic-dialog-box',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogContent],
  templateUrl: './generic-dialog-box.component.html',
  styleUrl: './generic-dialog-box.component.scss'
})

export class GenericDialogBoxComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
    },
    public dialogRef: MatDialogRef<GenericDialogBoxComponent>,
    public dialog: MatDialog
  ) { }
}
