import { Component } from '@angular/core';

@Component({
  selector: 'app-password-reset-view',
  standalone: true,
  imports: [],
  templateUrl: './password-reset-view.component.html',
  styleUrl: './password-reset-view.component.scss'
})
export class PasswordResetViewComponent {

}
