<div class="main">
    <div class="main-header" >
        <p class="mat-headline-3">Tela de template para desenvolvimento de novas Views </p>
    </div>
    <div class="main-content">
        <h1><b style="color: red;">Atenção</b></h1>
        <p>Este template deve ser usado para telas internas do sistema, ou seja, será usada apenas
             para páginas quando o usuário já esta logado. <b>Não</b> deve ser usado para páginas de 
             login; solicitação e acesso; e demais páginas que não exijam login.
        </p>

        <ol>
           <li>Crie a nova view com 'ng generate component /views/<b>nome-da-view</b>-view'. Substitua '<b>nome-da-view</b>' pelo nome da view (avá)</li> 
           <li>Copie o conteúdo deste html e cole no html da nova view</li>
           <li>Desenvolva dentro das divs 'main-header' e 'main-content'</li>
           <li>Copie o conteúdo do .scss deste componente para o .scss da nova view</li>
           <li>Remova as cores de fundo do arquivo .scss da nova view</li>

        </ol>
    </div>
    <!-- se for necessário o uso de um footer -->
    <div class="main-footer"></div>
</div>
