import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { BrasilApiServiceService } from '../../services/address/brasil-api-service.service';
import { CommonModule, NgIf } from '@angular/common';

@Component({
  selector: 'app-address-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    NgIf,
    CommonModule,
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent implements OnInit {
  zipCodeInput: string = '';
  protected addressForm!: FormGroup;

  constructor(
    private readonly BrasilApiService: BrasilApiServiceService,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  public getAddressOptions() {
    return this.addressForm.getRawValue();
  }

  private initializeForm() {
    this.addressForm = this.formBuilder.group({
      //Country informations
      id: [null],
      countryName: new FormControl({ value: null, disabled: true }, Validators.required),
      countryAcronym: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
      //Province informations
      provinceAcronym: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
      provinceName: new FormControl({ value: null, disabled: true }),
      //City informations
      cityName: new FormControl({ value: null, disabled: true }, Validators.required),
      //entity's address information
      zipCode: new FormControl(null, [Validators.required, BrazilianZipCodeValidator]),
      street: new FormControl({ value: null, disabled: true }, Validators.required),
      number: new FormControl(null, Validators.required),
      complement: new FormControl(null),
    });
  }

  protected async fillAddressFormGroupByZipCode(zipCode: string) {
    try {
      console.log(`${this.addressForm.get('zipCode')!.valid}`);
      if (this.addressForm.get('zipCode')!.valid) {
        this.BrasilApiService.getAddressInformationByZipCode(zipCode).subscribe(
          {
            next: async (data) => {
              this.addressForm.patchValue(data);
            },
            error: (error) => {
              if ((error as Response).status === 404) {
                this.addressForm.get('zipCode')?.setErrors({ zipCodeNotFound: true })
              }
            }
          },
        );
      }
    } catch {

    }
  }

}

export function BrazilianZipCodeValidator(formControl: AbstractControl): ValidationErrors | null {
  let zipCodeValue = zipCodeFormControlValueWithoutInvalidCaracters();
  let zipCodeLenght: number = zipCodeValue.length;
  if (zipCodeLenght === 8) {
    return null;
  }
  return { brazilianZipCodeInvalid: true };

  function zipCodeFormControlValueWithoutInvalidCaracters(): string {
    try {
      return (formControl.value as string).replaceAll('-', '').replaceAll('.', '');
    } catch {

    }
    return '';
  }
}
