import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { GlobalService } from '../../utils/global.service';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-modal-passage-details',
  standalone: true,
  imports: [MatIcon, MatDialogContent, MatIconModule, MatButtonModule, NgFor, NgIf],
  templateUrl: './modal-passage-details.component.html',
  styleUrl: './modal-passage-details.component.scss'
})
export class ModalPassageDetailsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalPassageDetailsComponent>,
    public globalService: GlobalService
  ) { this.data.obuid = Array.isArray(this.data.obuid) ? this.data.obuid : [this.data.obuid]; }

  closeModal() {
    this.dialogRef.close();
  }
}
