import { Component, InjectionToken } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MySidenavComponent } from './components/my-sidenav/my-sidenav.component';
import { ToggleThemeService } from './services/ToggleThemeService/toggle-theme.service';
import { TokenValidationService } from './services/AuthorizationService/token-validation.service';
import { NgIf } from '@angular/common';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { environment } from '../environment/environment';

export const ENVIRONMENT = new InjectionToken<{ [key: string]: any }>('environment');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, MySidenavComponent, NgIf, LoginViewComponent],
  providers: [
    { provide: ENVIRONMENT, useValue: environment }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'hase-app';
  isLoggedFlag?: boolean;

  constructor(private toggleThemeService: ToggleThemeService, public authorizationService: TokenValidationService) {
    this.isLoggedFlag = this.authorizationService.isLogged();
  }

  toggleTheme() {
    this.toggleThemeService.switchTheme();
  }

  checkIfUserIsLoggedIn() {
    return this.isLoggedFlag;
  }
}