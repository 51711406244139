<div class="main">
    <div class="main-header">
        <p class="mat-headline-3">Cadastro de equipamento</p>
    </div>
    <div class="main-content">
        <div class="column form">
            <p class="mat-headline-4">Identificação</p>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Modelo</mat-label>
                <input matInput [formControl]="equipmentModelFormControl" placeholder="Modelo">
                @if (equipmentModelFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Modelo do equipamento</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Número de série</mat-label>
                <input matInput [formControl]="equipmentSerialNumberFormControl" placeholder="Número de série">
                @if (equipmentSerialNumberFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Número de série do equipamento</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Versão do software</mat-label>
                <input matInput [formControl]="equipmentSoftwareVersionFormControl" placeholder="Versão do software">
                @if (equipmentSoftwareVersionFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Versão do software equipamento</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Username</mat-label>
                <input matInput [formControl]="equipmentUsernameFormControl" placeholder="Username">
                @if (equipmentUsernameFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Username do equipamento</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Senha</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="equipmentPasswordFormControl"
                    placeholder="">
                <button mat-icon-button matSuffix (click)="hidePasswordEvent($event)"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                @if (equipmentPasswordFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Senha do equipamento</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Repita a senha</mat-label>
                <input matInput [type]="hideConfirmPassword ? 'password' : 'text'"
                    [formControl]="equipmentConfirmPasswordFormControl" placeholder="">
                <button mat-icon-button matSuffix (click)="hideConfirmPasswordEvent($event)"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                    <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                @if (equipmentConfirmPasswordFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
                <mat-hint>Repita a senha do equipamento</mat-hint>
            </mat-form-field>

        </div>

    </div>
    <div class="main-footer">
        <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
            (click)="newEquipmentActionButton()">Cadastrar</button>
        <button mat-flat-button class="footer-cancel-button" color="primary"
            [routerLink]="'/equipments/list'">Cancelar</button>
    </div>
</div>