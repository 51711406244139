import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common'

@Component({
  selector: 'app-message-dialog-box',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogContent, NgIf],
  templateUrl: './message-dialog-box.component.html',
  styleUrl: './message-dialog-box.component.scss'
})

export class MessageDialogBoxComponent {

  public title: string = "default Title";
  public message: string = "default Message";
  responseMessageDto!: ResponseMessageDTO;
  responseMessageDtoValuesList: Map<string, string> = new Map();
  ApiResponse!: ApiResponse<ResponseMessageDTO>;

  @ViewChild('MessageContainer', { static: true }) MessageContainer!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      httpResponseBase: HttpResponseBase,
      route: any,
      envConfig: any,
    },
    public dialogRef: MatDialogRef<MessageDialogBoxComponent>,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.HttpResponseBaseHandler(this.data.httpResponseBase);
  }

  actionButton() {
    this.dialogRef.close();
    window.location.href = this.data.envConfig.HaseCloudAppEndpoint + this.data.route
  }

  HttpResponseBaseHandler(responseBase: HttpResponseBase) {

    const isSuccessfullOperation = responseBase.status >= 200 && responseBase.status < 300;
    const isUnsuccessfullOperation = responseBase.status >= 400 && responseBase.status < 600;
    if (isSuccessfullOperation) {

      const response: HttpResponse<ApiResponse<ResponseMessageDTO>> = responseBase as HttpResponse<ApiResponse<ResponseMessageDTO>>;
      if (response.body == undefined || response.body == null) {

        this.title = "UNHANDLED_EROR";
        //Message:
        const UnkownErrorMessageParagraph = this.renderer.createElement('p');
        this.renderer.appendChild(UnkownErrorMessageParagraph, this.renderer.createText('Erro desconhecido!'));
        this.renderer.appendChild(this.MessageContainer.nativeElement, UnkownErrorMessageParagraph);
      } else {

        this.ApiResponse = response.body;
        this.title = this.ApiResponse.haseStatusMessage;
        this.message = ` Código do retorno: ${this.ApiResponse.haseStatusCodeEnum}`;
        //Message
        const Message = this.renderer.createElement('p');
        this.renderer.appendChild(Message, this.renderer.createText('Operação realizada com sucesso!'));
        this.renderer.appendChild(this.MessageContainer.nativeElement, Message);

        const HaseStatusCodeEnumParagraph = this.renderer.createElement('p');
        this.renderer.appendChild(HaseStatusCodeEnumParagraph, this.renderer.createText(`Código da resposta: ${this.ApiResponse.haseStatusCodeEnum}`));
        this.renderer.appendChild(this.MessageContainer.nativeElement, HaseStatusCodeEnumParagraph);

        const HaseStatusMessageParagraph = this.renderer.createElement('p');
        this.renderer.appendChild(HaseStatusMessageParagraph, this.renderer.createText(`Mensagem da resposta: ${this.ApiResponse.haseStatusMessage}`));
        this.renderer.appendChild(this.MessageContainer.nativeElement, HaseStatusMessageParagraph);
      }
    }

    if (isUnsuccessfullOperation) {

      const errorResponse: HttpErrorResponse = responseBase as HttpErrorResponse;


      this.ApiResponse = errorResponse.error as ApiResponse<ResponseMessageDTO>;
      const responseHasData = this.ApiResponse.data != undefined && this.ApiResponse.data != null;
      this.title = `Erro`;
      this.message = `Código do erro: ${this.ApiResponse.haseStatusCodeEnum}. Mensagem de erro: ${this.ApiResponse.haseStatusMessage}.`;

      const Message = this.renderer.createElement('p');
      this.renderer.appendChild(Message, this.renderer.createText('Não foi possível realizar a operação!'));
      this.renderer.appendChild(this.MessageContainer.nativeElement, Message);

      const HaseStatusCodeEnumParagraph = this.renderer.createElement('p');
      this.renderer.appendChild(HaseStatusCodeEnumParagraph, this.renderer.createText(`Código do erro: ${this.ApiResponse.haseStatusCodeEnum}`));
      this.renderer.appendChild(this.MessageContainer.nativeElement, HaseStatusCodeEnumParagraph);

      const HaseStatusMessageParagraph = this.renderer.createElement('p');
      this.renderer.appendChild(HaseStatusMessageParagraph, this.renderer.createText(`Mensagem de erro: ${this.ApiResponse.haseStatusMessage}`));
      this.renderer.appendChild(this.MessageContainer.nativeElement, HaseStatusMessageParagraph);


      if (responseHasData) {
        const HaseStatusErrorDescriptionParagraph = this.renderer.createElement('p');
        this.renderer.appendChild(HaseStatusErrorDescriptionParagraph, this.renderer.createText(`Descrição do erro:`));
        this.renderer.appendChild(this.MessageContainer.nativeElement, HaseStatusErrorDescriptionParagraph);

        for (let prop in this.ApiResponse.data) {
          if (this.ApiResponse.data[prop] != undefined && this.ApiResponse.data[prop] != null)
            this.responseMessageDtoValuesList.set(prop, this.ApiResponse.data[prop] ?? '');
        }

        const ul = this.renderer.createElement('ul');
        this.responseMessageDtoValuesList.forEach(
          (value, key) => {
            const li = this.renderer.createElement('li')
            const text = this.renderer.createText(`${key} : ${value}`)
            this.renderer.appendChild(li, text);
            this.renderer.appendChild(ul, li);
          }
        );
        this.renderer.appendChild(this.MessageContainer.nativeElement, ul);

      }
    }
  }
}

export class ApiResponse<T> {
  haseStatusCodeEnum: number;
  haseStatusMessage: string;
  data!: T;
  constructor(haseStatusCodeEnum: number, haseStatusMessage: string) {
    this.haseStatusCodeEnum = haseStatusCodeEnum;
    this.haseStatusMessage = haseStatusMessage;
  }
}

export class ResponseMessageDTO {
  [key: string]: string | undefined;
  Message: string | undefined;
  Token: string | undefined;
  ResourceAlreadyExists: string | undefined;
  ResourceCreated: string | undefined;
  ResourceUpdated: string | undefined;
  ResourceDeleted: string | undefined;
  ResourceNotFound: string | undefined;
  ResourceInvalid: string | undefined;
  ResourceUnhandled: string | undefined;
  ExceptionMessage: string | undefined;
  InnerException: string | undefined;
  ExceptionStackTrace: string | undefined;
}