<div class="main">
  <div class="main-header">
    <p class="mat-headline-4">Documentação de componentes do sistema</p>
  </div>
  <div class="main-content">
    <button mat-button color="primary" (click)="toggleTheme()" class="trocar-de-tema">Trocar de tema</button><br>

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Buttons
          </mat-panel-title>
          <mat-panel-description>
            Action buttons
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-label">Basic</div>
        <div class="example-button-row">
          <button mat-button>Basic</button>
          <button mat-button color="primary">Primary</button>
          <button mat-button color="accent">Accent</button>
          <button mat-button color="warn">Warn</button>
          <button mat-button disabled>Disabled</button>
          <a mat-button href="https://www.google.com/" target="_blank">Link</a>
        </div><br>
        <mat-divider></mat-divider><br>

        <div class="example-label">Raised</div>
        <div class="example-button-row">
          <button mat-raised-button>Basic</button>
          <button mat-raised-button color="primary">Primary</button>
          <button mat-raised-button color="accent">Accent</button>
          <button mat-raised-button color="warn">Warn</button>
          <button mat-raised-button disabled>Disabled</button>
          <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
        </div><br>
        <mat-divider></mat-divider><br>

        <div class="example-label">Stroked</div>
        <div class="example-button-row">
          <button mat-stroked-button>Basic</button>
          <button mat-stroked-button color="primary">Primary</button>
          <button mat-stroked-button color="accent">Accent</button>
          <button mat-stroked-button color="warn">Warn</button>
          <button mat-stroked-button disabled>Disabled</button>
          <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
        </div><br>
        <mat-divider></mat-divider><br>

        <div class="example-label">Flat</div>
        <div class="example-button-row">
          <button mat-flat-button>Basic</button>
          <button mat-flat-button color="primary">Primary</button>
          <button mat-flat-button color="accent">Accent</button>
          <button mat-flat-button color="warn">Warn</button>
          <button mat-flat-button disabled>Disabled</button>
          <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Icon
          </mat-panel-title>
          <mat-panel-description>
            Icons examples
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-button-row-icon">
          <div class="example-flex-container">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>more_vert</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>home</mat-icon>
            </button>
            <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
              <mat-icon>menu</mat-icon>
            </button>
            <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
              <mat-icon>favorite</mat-icon>
            </button>
            <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Floating Actions Buttons (FAB)
          </mat-panel-title>
          <mat-panel-description>
            FAB examples
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-label">FAB</div>
        <div class="fab-container">
          <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
            <mat-icon>bookmark</mat-icon>
          </button>
          <button mat-fab color="warn" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-fab disabled aria-label="Example icon button with a heart icon">
            <mat-icon>favorite</mat-icon>
          </button>
        </div><br>

        <div class="example-label">Mini FAB</div>
        <div class="fab-container">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>plus_one</mat-icon>
          </button>
          <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
          <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Checkbox
          </mat-panel-title>
          <mat-panel-description>
            Checkbox examples
          </mat-panel-description>
        </mat-expansion-panel-header>
        <section class="example-section-checkbox" [formGroup]="toppings">
          <mat-checkbox class="example-margin" color="primary" formControlName="check1">Checkbox 1</mat-checkbox>
          <mat-checkbox class="example-margin" color="primary" formControlName="check2">Checkbox 2</mat-checkbox>
          <mat-checkbox class="example-margin" color="primary" formControlName="check3">Checkbox 3</mat-checkbox>
        </section><br>

        <section class="example-section">
          <span class="example-list-section-selectAll">
            <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
              (change)="setAll($event.checked)">
              {{task.name}}
            </mat-checkbox><br>
          </span>
          <span class="example-list-section">
            <ul>
              @for (subtask of task.subtasks; track subtask) {
              <li>
                <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                  (ngModelChange)="updateAllComplete()">
                  {{subtask.name}}
                </mat-checkbox>
              </li>
              }
            </ul>
          </span>
        </section>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Expansion Panel
          </mat-panel-title>
          <mat-panel-description>
            Expansion Panel example
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel [expanded]="stepExample === 1" (opened)="setStepExample(1)"
            (opened)="panelOpenStateExample = true" (closed)="panelOpenStateExample = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Panel 1 Title
              </mat-panel-title>
              <mat-panel-description>
                This is a summary of the content
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>This is the primary content of the panel.</p>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="stepExample === 2" (opened)="setStepExample(2)"
            (opened)="panelOpenStateExample = true" (closed)="panelOpenStateExample = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Panel 2 Title
              </mat-panel-title>
              <mat-panel-description>
                This is a summary of the content
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>This is the primary content of the panel.</p>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="stepExample === 3" (opened)="setStepExample(3)"
            (opened)="panelOpenStateExample = true" (closed)="panelOpenStateExample = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Panel 3 Title
              </mat-panel-title>
              <mat-panel-description>
                This is a summary of the content
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>This is the primary content of the panel.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Paginator
          </mat-panel-title>
          <mat-panel-description>
            Paginator example
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-paginator">
          <div class="example-paginator-button">
            <button mat-mini-fab class="button-selected">1</button>
            <button mat-mini-fab class="button">2</button>
          </div>
          <mat-paginator class="demo-paginator" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Select page">
          </mat-paginator>
        </div>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Input
          </mat-panel-title>
          <mat-panel-description>
            Input examples
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form class="example-form">
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
            @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
            <mat-error>Please enter a valid email address</mat-error>
            }
            @if (emailFormControl.hasError('required')) {
            <mat-error>Email is <strong>required</strong></mat-error>
            }
          </mat-form-field>
        </form>
        <mat-form-field class="example-form-field">
          <mat-label>Clearable input</mat-label>
          <input matInput type="text" [(ngModel)]="value">
          @if (value) {
          <button matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
          </button>
          }
        </mat-form-field>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Slide toggle
          </mat-panel-title>
          <mat-panel-description>
            Slide toggle example
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-slide-toggle class="example-margin" color="primary"></mat-slide-toggle>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step === 9" (opened)="setStep(9)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Sort header
          </mat-panel-title>
          <mat-panel-description>
            Sort header example
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table matSort (matSortChange)="sortData($event)">
          <thead class="thead-mat-sort">
            <tr>
              <th mat-sort-header="name">
                <mat-form-field class="login-form-field" appearance="fill" style="background-color: #00000000;">
                  <mat-label>Dessert (100g)</mat-label>
                  <input matInput />
                </mat-form-field>
              </th>
              <th mat-sort-header="calories">
                <mat-form-field class="login-form-field" appearance="fill">
                  <mat-label>Calories</mat-label>
                  <input matInput />
                </mat-form-field>
              </th>
              <th mat-sort-header="fat">
                <mat-form-field class="login-form-field" appearance="fill">
                  <mat-label>Fat (g)</mat-label>
                  <input matInput />
                </mat-form-field>
              </th>
              <th mat-sort-header="carbs">Carbs (g)</th>
              <th mat-sort-header="protein">Protein (g)</th>
            </tr>
          </thead>
          
          @for (dessert of sortedData; track dessert) {
          <tbody>
            <tr>
              <td>{{dessert.name}}</td>
              <td>{{dessert.calories}}</td>
              <td>{{dessert.fat}}</td>
              <td>{{dessert.carbs}}</td>
              <td>{{dessert.protein}}</td>
            </tr>
          </tbody>
          }
        </table>
      </mat-expansion-panel>
      
      <mat-expansion-panel [expanded]="step ===10" (opened)="setStep(10)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Dialog
          </mat-panel-title>
          <mat-panel-description>
            Dialog example
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button mat-raised-button (click)="openDialog('0ms', '0ms')">Open dialog</button>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>