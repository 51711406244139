import { Router, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { DesignSystemComponentsListComponent } from './views/design-system-components-list/design-system-components-list.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { EquipmentsListViewComponent } from './views/equipment/equipment-list-view/equipments-list-view.component';
import { TemplateViewComponent } from './views/template-view/template-view.component';
import { PasswordResetViewComponent } from './views/password-reset-view/password-reset-view.component';
import { ClientsListViewComponent } from './views/clients/clients-list-view/clients-list-view.component';
import { NewClientViewComponent } from './views/clients/new-client-view/new-client-view.component';
import { UpdateClientViewComponent } from './views/clients/update-client-view/update-client-view.component';
import { NewEquipmentViewComponent } from './views/equipment/new-equipment-view/new-equipment-view.component';
import { UpdateEquipmentViewComponent } from './views/equipment/update-equipment-view/update-equipment-view.component';
import { UserListViewComponent } from './views/user/user-list-view/user-list-view.component';
import { NewUserViewComponent } from './views/user/new-user-view/new-user-view.component';
import { UpdateUserViewComponent } from './views/user/update-user-view/update-user-view.component';
import { ProfileViewComponent } from './views/profile-view/profile-view.component';
import { DashboardViewComponent } from './views/dashboard-view/dashboard-view.component';
import { Injectable, NgModule, inject } from '@angular/core';
import { TokenValidationService } from './services/AuthorizationService/token-validation.service';

@Injectable({ providedIn: 'root' })
export class UserLoggedGuard {
    canActivate() {
        const authService = inject(TokenValidationService);
        const router = inject(Router);
        if (authService.logado) {
            return true;
        }
        router.navigateByUrl("/login");
        return false;
    }
}

@Injectable({ providedIn: 'root' })
export class UserNotLoggedGuard {
    canActivate() {
        const authService = inject(TokenValidationService);
        const router = inject(Router);
        if (!authService.logado) {
            return true;
        }
        router.navigateByUrl("dashboard");
        return false;
    }
}

export const routes: Routes = [
    //Documentation
    {
        path: 'docs',
        component: DesignSystemComponentsListComponent,
        canActivate: mapToCanActivate([UserLoggedGuard])
    },
    //Test componets
    { path: 'templateView', component: TemplateViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    //DASHBOARD
    { path: 'dashboard', component: DashboardViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    // EQUIPMENTS
    { path: 'equipments/list', component: EquipmentsListViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'equipments/new', component: NewEquipmentViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'equipments/:id', component: UpdateEquipmentViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'equipments/newSale', component: DesignSystemComponentsListComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    //USERS
    { path: 'users/list', component: UserListViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'users/new', component: NewUserViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'users/:id', component: UpdateUserViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'users/accessRequests', component: DesignSystemComponentsListComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    //CLIENTS
    { path: 'companies/list', component: ClientsListViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'companies/new', component: NewClientViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    { path: 'companies/:id', component: UpdateClientViewComponent, canActivate: mapToCanActivate([UserLoggedGuard]) },
    //User Session
    { path: 'profile', component: ProfileViewComponent },
    { path: 'login', component: LoginViewComponent, canActivate: mapToCanActivate([UserNotLoggedGuard]) },
    { path: '', component: LoginViewComponent, canActivate: mapToCanActivate([UserNotLoggedGuard]) },
    { path: 'passwordReset', component: PasswordResetViewComponent, canActivate: mapToCanActivate([UserNotLoggedGuard]) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }