export class AddressDto {
  street: string;
  number: string;
  complement: string;
  zipCode: string;
  cityId: string = '';
  id!: string;
  city: CityDto;

  
  constructor(
    street: string,
    number: string,
    complement: string,
    zipCode: string,
    city: CityDto
  ) {
    this.street = street;
    this.number = number;
    this.complement = complement;
    this.zipCode = zipCode;
    this.city = city;
  }

  toJson() {
    return {
      street: this.street,
      number: this.number,
      complement: this.complement,
      zipCode: this.zipCode,
      cityId: this.cityId,
      city: this.city.toJson(),
    };
  }
}

export class CityDto {
  id!: string;
  province: ProvinceDto;
  name: string;

  constructor(province: ProvinceDto, name: string) {
    this.province = province;
    this.name = name;
  }

  toJson() {
    return {
      province: this.province.toJson(),
      name: this.name,
    };
  }
}

export class ProvinceDto {
  name: string;
  acronym: string;
  country: CountryDto;

  constructor(name: string, acronym: string, country: CountryDto) {
    this.name = name;
    this.acronym = acronym;
    this.country = country;
  }

  toJson() {
    return {
      name: this.name,
      acronym: this.acronym,
      country: this.country.toJson(),
    };
  }
}

export class CountryDto {
  name: string;
  acronym: string;

  constructor(name: string, acronym: string) {
    this.name = name;
    this.acronym = acronym;
  }

  toJson() {
    return {
      name: this.name,
      acronym: this.acronym,
    };
  }
}
