export class UpdateEquipmentDto {
    id!: string;
    serialNumber!: string;
    softwareVersion!: string;
    username!: string;
    password!: string;
    confirmPassword!: string;
    companyId!: string;
    modelId!: string;
    constructor(
        id: string,
        serialNumber: string,
        softwareVersion: string,
        username: string,
        password: string,
        confirmPassword: string,
    ) {
        this.id = id;
        this.serialNumber = serialNumber;
        this.softwareVersion = softwareVersion;
        this.username = username;
        this.password = password;
        this.confirmPassword = confirmPassword;
    }
    toJson() {
        return {
            id: this.id,
            serialNumber: this.serialNumber,
            softwareVersion: this.softwareVersion,
            username: this.username,
            password: this.password,
            confirmPassword: this.confirmPassword
        };
    }
}