import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

type Theme = 'light-theme' | 'dark-theme'

@Injectable({
  providedIn: 'root'
})
export class ToggleThemeService {
  defaultTheme: Theme = 'light-theme';
  constructor(@Inject(DOCUMENT) private document:Document){
    this.document.body.classList.add(this.defaultTheme);
  }

  switchTheme(): void{
   if(document.body.classList.contains('dark-theme')){
      this.document.body.classList.replace('dark-theme', 'light-theme');
    }else{
      this.document.body.classList.replace('light-theme', 'dark-theme');
    }
  }
}
