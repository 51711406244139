import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-extended-logo',
  standalone: true,
  imports: [ RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './extended-logo.component.html',
  styleUrl: './extended-logo.component.scss'
})
export class ExtendedLogoComponent {

}
