import { Component, ViewChild, Inject } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../app.component';
import { DialogBoxComponent } from '../../../components/delete-dialog-box/delete-dialog-box.component';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export interface UserList {
  name: string;
  cpf: string;
  email: string;
  worksForCnpj: string;
}

@Component({
  selector: 'app-user-list-view',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet
  ],
  templateUrl: './user-list-view.component.html',
  styleUrl: './user-list-view.component.scss'
})
export class UserListViewComponent {
  statusCode: number | undefined;
  displayedColumns: string[] = ['name', 'cpf', 'email', 'worksForCnpj', 'acoes', 'active'];
  dataSource = new MatTableDataSource();

  constructor(private _liveAnnouncer: LiveAnnouncer, public dialog: MatDialog, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, public cookieManagerService: CookieManagerService) {
    this.sort = {} as MatSort;
  }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarDados(): void {
    const serviceRoute: string = '/users';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      { headers, observe: 'response' }
    ).subscribe(
      (response) => {
        this.statusCode = response.status;
        this.dataSource = new MatTableDataSource(((response as any).body as any).data);
      },
      (error) => {
      }
    );
  }

  openDialogDeleteActionButton(idUser: string, nameUser: string) {
    this.dialog.open(DialogBoxComponent, {
      data: {
        id: idUser,
        title: "Deletar usuário",
        message: `Deseja deletar o usuário ${nameUser}?`,
        confirmMessage: "Usuário deletado com sucesso",
        endpoint: "users",
        envConfig: this.envConfig,
        updateUserList: this.carregarDados.bind(this)
      }
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  changeStatusUser(event: any, user: any) {
    const serviceRoute: string = '/users';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });


    const newDataEquipment = {
      id: user.id,
      addressId: user.addressId,
      cpf: user.cpf,
      worksForCnpj: user.worksForCnpj,
      name: user.name,
      username: user.username,
      role: user.role,
      email: user.email,
      isActive: event.checked,
    }

    user.isActive = !user.isActive;

    this.http.patch<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      newDataEquipment,
      { headers }
    ).subscribe((responsebody) => {
      console.log("updated")
    });
  }
}
