<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Gerenciar Equipamentos</p>
    <div>
      <button mat-flat-button class="paginator-button" color="primary" [routerLink]="'/equipments/new'">+
        Cadastrar equipamento</button>
    </div>
  </div>
  <div class="main-content">
    <div class="table-component">

      <div class="table-component-body">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input form" appearance="outline">
                <mat-label>ID</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let equipment"> {{equipment.id}} </td>
          </ng-container>

          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>Número de série</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let equipment"> {{equipment.serialNumber}} </td>
          </ng-container>


          <ng-container matColumnDef="softwareVersion">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>Versão do software</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let equipment"> {{equipment.softwareVersion}} </td>
          </ng-container>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let equipment"> {{equipment.username}} </td>
          </ng-container>

          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef>
              Ações
            </th>
            <td mat-cell *matCellDef="let equipment">
              <button mat-icon-button
                (click)="openDialogTransferActionButton(equipment.id, equipment.companyId, equipment.modelId, equipment.serialNumber, equipment.softwareVersion, equipment.username)">
                <mat-icon>sync_alt</mat-icon>
              </button>
              <button mat-icon-button [routerLink]="'/equipments/'+equipment.id">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openDialogDeleteActionButton(equipment.id, equipment.model)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>
              Status
            </th>
            <td mat-cell *matCellDef="let equipment">
              <div class="status-container">
                <mat-slide-toggle [ngModel]="equipment.isActive" color="primary"
                  (change)="changeStatusEquipment($event, equipment)"></mat-slide-toggle>
                <span class="status-content">{{equipment.isActive ? 'Ativo' : 'Inativo' }}</span>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="table-component-footer">
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <div class="paginator">
            <mat-paginator class="demo-paginator" (page)="carregarDados($event.pageIndex, $event.pageSize)"
              [length]="equipmentsCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>