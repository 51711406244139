import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieManagerService } from '../TokenService/cookier-manager-service.service';



@Injectable({
  providedIn: 'root'
})
export class TokenValidationService {
  public isLoggedFlag: boolean = false;
  private authToken: string = '';

  constructor(@Inject(DOCUMENT) private document: Document, public dialog: MatDialog, private cookieManager: CookieManagerService) {
    this.authToken = this.cookieManager.getAuthToken()
    this.isLoggedFlag = !this.isTokenExpiredOrInvalid(this.authToken);
  }

  isLogged(): boolean {
    this.authToken = this.cookieManager.getAuthToken();
    this.isLoggedFlag = !this.isTokenExpiredOrInvalid(this.authToken);
    return this.isLoggedFlag;
  }

  get logado(): boolean {
    this.authToken = this.cookieManager.getAuthToken();
    this.isLoggedFlag = !this.isTokenExpiredOrInvalid(this.authToken);
    return this.isLoggedFlag;
  }

  public isTokenExpiredOrInvalid(authToken: string): boolean {
    if (this.authToken === '')
      return true
    let decodedToken: any = this.cookieManager.decodedToken;

    const expireDateInUnixEpoch = new Date(decodedToken.exp * 1000).getTime();
    const currentDateInUnixEpoch = getCurrentDateInUnixEpoch();

    return this.isTokenExpired(expireDateInUnixEpoch, currentDateInUnixEpoch);


    function getCurrentDateInUnixEpoch() {
      const currentDate = new Date();
      currentDate.setUTCHours(new Date().getUTCHours());
      const currentDateInUnixEpoch = currentDate.getTime();

      return currentDateInUnixEpoch;
    }
  }

  private isTokenExpired(expireDateInUnixEpoch: number, currentDateInUnixEpoch: number): boolean {
    let precedes: boolean = expireDateInUnixEpoch < currentDateInUnixEpoch
    if (precedes) {
      this.cookieManager.deleteAuthToken();
      return true
    }
    return false
  }
  canActivateFn() {
    //return this.cookieManager.getAuthToken()
    return true
  }
}