<mat-dialog-content class="container-modal">
  <header>
    <mat-icon class="icon-phone">speaker_phone</mat-icon>
    <h2>Transferência de Equipamento</h2>
  </header>
  <div class="container-content">
    <div>
      <h3>Dados da atual empresa do equipamento</h3>
      <app-input [value]="companyData.cnpj" [disabled]="true" [label]="'CNPJ'" [placeholder]="''"
        [control]="currentCNPJForm" [hint]="''"></app-input>
      <app-input [value]="companyData.corporateName" [disabled]="true" [label]="'Razão Social'" [placeholder]="''"
        [control]="currentcorporateNameForm" [hint]="''"></app-input>
    </div>
    <hr>
    <mat-icon class="icon-repeat">repeat</mat-icon>
    <hr>
    <div>
      <h3>Dados da nova empresa do equipamento</h3>
      <app-input [label]="'CNPJ'" [placeholder]="''" [control]="newCNPJForm" [hint]="''" [digitCount]="14"></app-input>
      <app-input [label]="'Razão Social'" [placeholder]="''" [disabled]="true" [value]="newCorporateName"
        [control]="newcorporateNameForm" [hint]="''"></app-input>
    </div>
  </div>
  <footer>
    <button mat-stroked-button class="footer-cancel-button" color="primary" (click)="closeModal()">Cancelar</button>
    <button mat-flat-button class="footer-transfer-button" color="primary" (click)="updateEquipmentCompany()"
      [disabled]="searchDisabled && (!newCNPJForm.valid)">Transferir</button>
  </footer>
</mat-dialog-content>