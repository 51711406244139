<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Gerenciar Usuários</p>
    <div>
      <button mat-flat-button class="paginator-button" color="primary" [routerLink]="'/users/new'">+
        Cadastrar usuário</button>
    </div>
  </div>
  <div class="main-content">
    <div class="table-component">

      <div class="table-component-body">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input form" appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.name}} </td>
          </ng-container>

          <ng-container matColumnDef="cpf">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>CPF</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.cpf}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.email}} </td>
          </ng-container>

          <ng-container matColumnDef="worksForCnpj">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input" appearance="outline">
                <mat-label>Empresa</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.worksForCnpj}} </td>
          </ng-container>

          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef>
              Ações
            </th>
            <td mat-cell *matCellDef="let user">
              <button mat-icon-button [routerLink]="'/users/'+user.id">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openDialogDeleteActionButton(user.id, user.name)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>
              Status
            </th>
            <td mat-cell *matCellDef="let user">
              <div class="status-container">
                <mat-slide-toggle [ngModel]="user.isActive" color="primary"
                  (change)="changeStatusUser($event, user)"></mat-slide-toggle>
                <span class="status-content">{{user.isActive ? 'Ativo' : 'Inativo' }}</span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>
</div>