import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../app.component';
import { DialogBoxComponent } from '../../../components/delete-dialog-box/delete-dialog-box.component';
import { EquipmentTransferComponent } from '../../../components/equipment-transfer/equipment-transfer.component';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-equipment-list-view',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet
  ],
  templateUrl: './equipments-list-view.component.html',
  styleUrl: './equipments-list-view.component.scss'
})
export class EquipmentsListViewComponent implements OnInit {
  displayedColumns: string[] = ['id', 'serialNumber', 'softwareVersion', 'username', 'acoes', 'active'];
  dataSource = new MatTableDataSource();

  constructor(private _liveAnnouncer: LiveAnnouncer, public dialog: MatDialog, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, public cookieManagerService: CookieManagerService) {
    this.sort = {} as MatSort;
  }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarDados(): void {
    const serviceRoute: string = '/equipments';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}?pageNumber=1&pageSize=3`,
      { headers }
    ).subscribe((responsebody) => {
      this.dataSource = new MatTableDataSource((responsebody as any).data)
    });
  }

  openDialogDeleteActionButton(idEquipment: string, modelEquipment: string) {
    this.dialog.open(DialogBoxComponent, {
      data: {
        id: idEquipment,
        title: "Deletar equipamento",
        message: `Deseja deletar o equipamento ${idEquipment} cujo modelo é ${modelEquipment}?`,
        confirmMessage: "Equipamento deletado com sucesso",
        endpoint: "equipments",
        envConfig: this.envConfig,
        updateUserList: this.carregarDados.bind(this)
      }
    });
  }

  openDialogTransferActionButton(idEquipment: string, idCompany: string, idModel: string, serialNumber: string, softwareVersion: string, username: string) {
    this.dialog.open(EquipmentTransferComponent, {
      data: {
        equipmentId: idEquipment,
        companyId: idCompany,
        envConfig: this.envConfig,
        modelId: idModel,
        serialNumber: serialNumber,
        softwareVersion: softwareVersion,
        username: username,
        updateEquipmentList: this.carregarDados.bind(this)
      }
    });
  }

  changeStatusEquipment(event: any, equipment: any) {
    const serviceRoute: string = '/equipments';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const newDataEquipment = {
      id: equipment.id,
      companyId: equipment.companyId,
      modelId: equipment.modelId,
      serialNumber: equipment.serialNumber,
      softwareVersion: equipment.softwareVersion,
      username: equipment.username,
      isActive: event.checked,
      password: "",
      confirmPassword: "",
    }

    equipment.isActive = !equipment.isActive;

    this.http.patch<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      newDataEquipment,
      { headers }
    ).subscribe((responsebody) => {
      console.log("updated")
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
