export class UpdateClientDto {
    cnpj!: string;
    companyName!: string;
    constructor(
      cnpj: string,
      companyName: string,
    ) {
      this.cnpj = cnpj;
      this.companyName = companyName;
    }
    toJson() {
      return {
        cnpj: this.cnpj,
        companyName: this.companyName,
      };
    }
  }
  