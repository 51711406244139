<ng-container>
    <div class="container">
        <h2 mat-dialog-title>
            {{title}}
        </h2>
        <mat-dialog-content>
            <div #MessageContainer>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="actionButtonsContainer">
            <button mat-button mat-dialog-close cdkFocusInitial color="primary" (click)="actionButton()">Ok</button>
        </mat-dialog-actions>
    </div>
</ng-container>