export class DomainBaseDto {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}

export class DomainDto extends DomainBaseDto {

}
