<form [formGroup]="addressForm">
    <div class="column form">
        <p class="mat-headline-4">Endereço</p>

        <mat-form-field appearance="outline" class="form-endereco">
            <mat-label>CEP</mat-label>
            <input matInput #zipCodeInput (blur)="this.fillAddressFormGroupByZipCode(zipCodeInput.value);"
                placeholder="CEP" formControlName="zipCode">
            @if(addressForm.get('zipCode')?.hasError('required')){
            <mat-error>Campo obrigatório</mat-error>
            }
            @if(addressForm.get('zipCode')?.hasError('brazilianZipCodeInvalid')){
            <mat-error>CEP Inválido</mat-error>
            }
            @if(addressForm.get('zipCode')?.hasError('zipCodeNotFound')){
            <mat-error>CEP não encontrado</mat-error>
            }


            <mat-hint>CEP</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco">
            <mat-label>País</mat-label>
            <input matInput placeholder="Country" formControlName="countryName" readonly>
            <mat-hint>País</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco">
            <mat-label>Estado</mat-label>
            <input matInput placeholder="Estado" formControlName="provinceAcronym">
            <mat-hint>Estado do usuário</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco">
            <mat-label>Cidade</mat-label>
            <input matInput placeholder="Cidade" formControlName="cityName">
            <mat-hint>Cidade do usuário</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco" style="width: 73%; padding-right: 2%;">
            <mat-label>Rua</mat-label>
            <input matInput placeholder="Rua" formControlName="street">
            <mat-hint>Rua do usuário</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco" style="width: 25%;">
            <mat-label>Número</mat-label>
            <input matInput placeholder="Número" formControlName="number">
            <mat-hint>Número</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-endereco">
            <mat-label>Complemento</mat-label>
            <input matInput placeholder="Complemento" formControlName="complement">
            <mat-hint>Complemento do usuário</mat-hint>
        </mat-form-field>
    </div>
</form>