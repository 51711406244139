<mat-form-field appearance="outline" class="form-identificacao">
  <mat-label>{{ label }}</mat-label>
  @if (disabled) {
  <input matInput disabled [placeholder]="placeholder" [value]="value" [type]="type">
  }
  @if (!disabled) {
  <input matInput [formControl]="control" [placeholder]="placeholder" [type]="type">
  }
  @if (control.hasError('required')) {
  <mat-error>Campo obrigatório</mat-error>
  }
  @if (control.hasError('minlength') || control.hasError('maxlength')) {
  <mat-error>O campo deve ter {{digitCount}} digitos</mat-error>
  }
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>