export class NewEquipmentDto {
    model!: ModelDto;
    serialNumber!: string;
    softwareVersion!: string;
    username!: string;
    password!: string;
    confirmPassword!: string;
    constructor(
        model: ModelDto,
        serialNumber: string,
        softwareVersion: string,
        username: string,
        password: string,
        confirmPassword: string,
    ) {
        this.model = model;
        this.serialNumber = serialNumber;
        this.softwareVersion = softwareVersion;
        this.username = username;
        this.password = password;
        this.confirmPassword = confirmPassword;
    }
    toJson() {
        return {
            model: this.model,
            serialNumber: this.serialNumber,
            softwareVersion: this.softwareVersion,
            username: this.username,
            password: this.password,
            confirmPassword: this.confirmPassword
        };
    }
}

export class ModelDto {
    name!: string;
    constructor(
        name: string,
    ) {
        this.name = name;
    }
    toJson() {
        return {
            name: this.name,
        };
    }
}