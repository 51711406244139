<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Cadastro de usuário</p>
  </div>
  <div class="main-content">
    <div class="column form">
      <p class="mat-headline-4">Identificação</p>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>CPF</mat-label>
        <input matInput [formControl]="userCpfFormControl" placeholder="CPF">
        @if (userCpfFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>CPF do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Nome</mat-label>
        <input matInput [formControl]="nameFormControl" placeholder="Nome">
        @if (nameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Nome do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>E-mail</mat-label>
        <input matInput [formControl]="userEmailFormControl" placeholder="E-mail">
        @if (userEmailFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>E-mail do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Username</mat-label>
        <input matInput [formControl]="userNameFormControl" placeholder="Username">
        @if (userNameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Username do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Empresa</mat-label>
        <input matInput [formControl]="worksForCnpjFormControl" placeholder="CNPJ da empresa">
        @if (worksForCnpjFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>CNPJ da empresa que o usuário trabalha</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Senha</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="userPasswordFormControl"
          placeholder="">
        <button mat-icon-button matSuffix (click)="hidePasswordEvent($event)" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        @if (userPasswordFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Senha do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Repita a senha</mat-label>
        <input matInput [type]="hideConfirmPassword ? 'password' : 'text'"
          [formControl]="userConfirmPasswordFormControl" placeholder="">
        <button mat-icon-button matSuffix (click)="hideConfirmPasswordEvent($event)" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideConfirmPassword">
          <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        @if (userConfirmPasswordFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Repita a senha do usuário</mat-hint>
      </mat-form-field>

    </div>
    <div class="column form">
      <p class="mat-headline-4">Endereço</p>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>CEP</mat-label>
        <input matInput placeholder="CEP" [formControl]="zipCodeFormControl">
        <mat-hint>CEP do usuario</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Cidade</mat-label>
        <input matInput placeholder="Cidade" [formControl]="cityFormControl">
        <mat-hint>Cidade do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Estado</mat-label>
        <input matInput placeholder="Estado" [formControl]="provinceFormControl">
        <mat-hint>Estado do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Sigla Estado</mat-label>
        <input matInput placeholder="Sigla Estado" [formControl]="provinceAcronymFormControl">
        <mat-hint>Sigla da estado do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>País</mat-label>
        <input matInput placeholder="País" [formControl]="countryFormControl">
        <mat-hint>País do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Sigla País</mat-label>
        <input matInput placeholder="Sigla País" [formControl]="countryAcronymFormControl">
        <mat-hint>Sigla da País do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco" style="width: 73%; padding-right: 2%;">
        <mat-label>Rua</mat-label>
        <input matInput placeholder="Rua" [formControl]="streetFormControl">
        <mat-hint>Rua do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco" style="width: 25%;">
        <mat-label>Número</mat-label>
        <input matInput placeholder="Número" [formControl]="numberFormControl">
        <mat-hint>Número</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Complemento</mat-label>
        <input matInput placeholder="Complemento" [formControl]="complementFormControl">
        <mat-hint>Complemento do usuário</mat-hint>
      </mat-form-field>
    </div>

  </div>
  <div class="main-footer">
    <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
      (click)="newUserActionButton()">Cadastrar</button>
    <button mat-flat-button class="footer-cancel-button" color="primary" [routerLink]="'/users/list'">Cancelar</button>
  </div>
</div>