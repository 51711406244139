import { Component, Inject } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterModule, RouterOutlet, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NewUserDto } from '../../../dto/NewUserDto';
import { AddressDto, ProvinceDto, CountryDto, CityDto } from '../../../dto/addressDto';
import { MatIconModule } from '@angular/material/icon';
import { ENVIRONMENT } from '../../../app.component';
import { MessageDialogBoxComponent } from '../../../components/message-dialog-box/message-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';


@Component({
  selector: 'app-new-user-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
    MatIconModule,
  ],
  templateUrl: './new-user-view.component.html',
  styleUrl: './new-user-view.component.scss'
})
export class NewUserViewComponent {
  hidePassword = true;
  hidePasswordEvent(event: MouseEvent) {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  hideConfirmPassword = true;
  hideConfirmPasswordEvent(event: MouseEvent) {
    this.hideConfirmPassword = !this.hideConfirmPassword;
    event.stopPropagation();
  }

  constructor(
    public cookieManagerService: CookieManagerService,
    private http: HttpClient,
    @Inject(ENVIRONMENT) public envConfig: any,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  userCpfFormControl = new FormControl('', [Validators.required]);
  nameFormControl = new FormControl('', [Validators.required]);
  userNameFormControl = new FormControl('', [Validators.required]);
  userEmailFormControl = new FormControl('', [Validators.required]);
  worksForCnpjFormControl = new FormControl('', [Validators.required]);
  userPasswordFormControl = new FormControl('', [Validators.required]);
  userConfirmPasswordFormControl = new FormControl('', [Validators.required]);
  streetFormControl = new FormControl('', [Validators.required]);
  numberFormControl = new FormControl('', [Validators.required]);
  complementFormControl = new FormControl('');
  zipCodeFormControl = new FormControl('', [Validators.required]);
  cityFormControl = new FormControl('', [Validators.required]);
  provinceFormControl = new FormControl('', [Validators.required]);
  provinceAcronymFormControl = new FormControl('', [Validators.required]);
  countryFormControl = new FormControl('', [Validators.required]);
  countryAcronymFormControl = new FormControl('', [Validators.required]);

  async newUserActionButton() {
    const serviceRoute: string = '/users';

    const country = new CountryDto(
      this.countryFormControl.value ?? '',
      this.countryAcronymFormControl.value ?? ''
    );

    const province = new ProvinceDto(
      this.provinceFormControl.value ?? '',
      this.provinceAcronymFormControl.value ?? '',
      country ?? ''
    );

    const city = new CityDto(
      province ?? '',
      this.cityFormControl.value ?? ''
    );

    const address = new AddressDto(
      this.streetFormControl.value ?? '',
      this.numberFormControl.value ?? '',
      this.complementFormControl.value ?? '',
      this.zipCodeFormControl.value ?? '',
      city ?? ''
    )

    const user = new NewUserDto(
      this.userCpfFormControl.value ?? '',
      this.nameFormControl.value ?? '',
      this.userNameFormControl.value ?? '',
      this.userEmailFormControl.value ?? '',
      this.worksForCnpjFormControl.value ?? '',
      this.userPasswordFormControl.value ?? '',
      this.userConfirmPasswordFormControl.value ?? '',
      address
    );

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.post<object>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      user,
      { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Usuário cadastrado com sucesso", "Fechar", { duration: 5000 });
        this.router.navigate(['/users/list'])
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }
}