import { Component } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { UpdateClientDto } from '../../dto/UpdateClientDto';

@Component({
  selector: 'profile-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
    MatIconModule
  ],
  templateUrl: './profile-view.component.html',
  styleUrl: './profile-view.component.scss'
})
export class ProfileViewComponent {
  private backend_endpoint: string = 'http://127.0.0.1:5000';
  constructor(private http: HttpClient) { }

  userNameFormControl = new FormControl('', [Validators.required]);
  userCpfFormControl = new FormControl('', [Validators.required]);
  userEmailFormControl = new FormControl('', [Validators.required]);
  userworksForCnpjFormControl = new FormControl('', [Validators.required]);

  clientCnpjFormControl = new FormControl('', [Validators.required]);
  clientRazaoSocialFormControl = new FormControl('', [Validators.required]);

  updateUserActionButton() {
    const serviceRoute: string = '/profile';
    let client = new UpdateClientDto(
      this.clientCnpjFormControl.value ?? '',
      this.clientRazaoSocialFormControl.value ?? '',
    );
    this.http.post<string>(
      `${this.backend_endpoint}${serviceRoute}`,
      client.toJson()
    );
  }
}
