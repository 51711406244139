<div class="main">
    <div class="main-header">
        <p class="mat-headline-3">Dados de usuário</p>
    </div>
    <div class="main-content">
        <div class="column form">
            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>Nome</mat-label>
                <input matInput [formControl]="userNameFormControl" placeholder="Nome">
                @if (clientCnpjFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
            </mat-form-field><br>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>CPF</mat-label>
                <input matInput [formControl]="userCpfFormControl" placeholder="CPF">
                @if (clientCnpjFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
            </mat-form-field><br>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>E-mail</mat-label>
                <input matInput [formControl]="userEmailFormControl" placeholder="E-mail">
                @if (clientRazaoSocialFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
            </mat-form-field><br>

            <mat-form-field appearance="outline" class="form-identificacao">
                <mat-label>CNPJ da empresa</mat-label>
                <input matInput [formControl]="userworksForCnpjFormControl" placeholder="CNPJ da empresa">
                @if (clientRazaoSocialFormControl.hasError('required')) {
                <mat-error>Campo obrigatório</mat-error>
                }
            </mat-form-field>
            
        </div>
        <div class="column profile-photo">
            <button mat-icon-button [routerLink] ="'profile'" class="side-button" style="margin-bottom: 15px;">
                <mat-icon color="primary" style="transform: scale(6);">account_circle</mat-icon>
              </button>
        </div>
    </div>
    <div class="main-footer">
        <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
                (click)="updateUserActionButton()">Atualizar</button>
    </div>
</div>