import { Component } from '@angular/core';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SquareLogoComponent } from '../square-logo/square-logo.component';
import { MatIconModule } from '@angular/material/icon';
import { SidenavIconButtonComponent } from '../sidenav-icon-button/sidenav-icon-button.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';


@Component({
  selector: 'app-my-sidenav',
  templateUrl: './my-sidenav.component.html',
  styleUrl: './my-sidenav.component.scss',
  standalone: true,
  imports: [MatSidenavModule, MatButtonModule, RouterOutlet, RouterLink, RouterLinkActive, SquareLogoComponent, MatIconButton, MatIconModule, SidenavIconButtonComponent],
})
export class MySidenavComponent {
  showClientes = false;
  showEquipment = false;
  showUser = false;
  ;
  constructor(public cookieManagerService: CookieManagerService, private router: Router) {

  }

  public cliente() {
    this.showClientes = !this.showClientes;
    if (this.showEquipment)
      this.showEquipment = !this.showEquipment;
    if (this.showUser)
      this.showUser = !this.showUser;
  }

  public equipamento() {
    this.showEquipment = !this.showEquipment;
    if (this.showClientes)
      this.showClientes = !this.showClientes;
    if (this.showUser)
      this.showUser = !this.showUser;
  }

  public usuario() {
    this.showUser = !this.showUser;
    if (this.showEquipment)
      this.showEquipment = !this.showEquipment;
    if (this.showClientes)
      this.showClientes = !this.showClientes;
  }

  public dashboard() {
    if (this.showEquipment)
      this.showEquipment = !this.showEquipment;
    if (this.showClientes)
      this.showClientes = !this.showClientes;
    if (this.showUser)
      this.showUser = !this.showUser;
  }

  public logout() {
    this.cookieManagerService.deleteAuthTokenWithoutDialogBox();
    this.router.navigateByUrl("login").then(() => {
      window.location.reload();
    });
  }
}
