import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';
import { GenericDialogBoxComponent } from '../../components/generic-dialog-box/generic-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieManagerService {
  readonly authTokenCookieName = 'authToken'
  authToken: string = '';
  decodedToken: any;

  constructor(private cookieService: CookieService, public dialog: MatDialog) {
    this.authToken = this.getAuthToken();
    this.decodeAuthToken(this.authToken);
  }

  public getAuthToken(): string {
    if (this.cookieService.check(this.authTokenCookieName))
      return this.cookieService.get(this.authTokenCookieName)
    return ''
  }

  public setAuthToken(tokenToBeSet: string) {
    this.decodeAuthToken(tokenToBeSet)
    this.cookieService.set(this.authTokenCookieName, tokenToBeSet,) //{ expires: new Date(decodedToken.exp * 1000).getTime() }
  }

  private decodeAuthToken(tokenToBeDecoded: string): boolean {
    try {
      this.decodedToken = jwtDecode(tokenToBeDecoded)
    } catch {
      return false;
    }
    return true
  }

  public async deleteAuthToken() {
    await lastValueFrom(this.dialog.open(
      GenericDialogBoxComponent,
      {
        data: {
          title: "Sessão expirada",
          message: "Sua sessão expirou, faça login novamente!"
        }
      }
    ).afterClosed())

    this.cookieService.delete(this.authTokenCookieName);
    window.location.reload();
  }

  public async deleteAuthTokenWithoutDialogBox() {
    this.cookieService.delete(this.authTokenCookieName);
  }
}
