import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-square-logo',
  standalone: true,
  imports: [ RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './square-logo.component.html',
  styleUrl: './square-logo.component.scss'
})
export class SquareLogoComponent {

}
