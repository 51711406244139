import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { ApiResponse, MessageDialogBoxComponent, ResponseMessageDTO } from '../message-dialog-box/message-dialog-box.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';
@Component({
  selector: 'app-dialog-box',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogContent],
  templateUrl: './delete-dialog-box.component.html',
  styleUrl: './delete-dialog-box.component.scss'
})

export class DialogBoxComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      title: string,
      message: string,
      endpoint: string,
      envConfig: any,
      updateUserList: () => void
    },
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    private http: HttpClient,
    public dialog: MatDialog,
    public cookieManagerService: CookieManagerService
  ) { }

  deleteActionButton() {
    const serviceRoute: string = `/${this.data.endpoint}/`;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.delete<ApiResponse<ResponseMessageDTO>>(
      `${this.data.envConfig.HaseCloudCoreEndpoint}${serviceRoute}${this.data.id}`,
      { headers, observe: 'response' }
    ).subscribe((response) => {
      this.dialogRef.close();
      this.dialog.open(MessageDialogBoxComponent, {
        data: {
          haseStatusCodeEnum: response.body?.haseStatusCodeEnum,
          haseStatusMessage: response.body?.haseStatusMessage,
        }
      });
      this.data.updateUserList();
    });
  }
}
