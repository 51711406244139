import { Component, Inject } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UpdateUserDto } from '../../../dto/UpdateUserDto';
import { ENVIRONMENT } from '../../../app.component';
import { AddressDto, CityDto, CountryDto, ProvinceDto } from '../../../dto/addressDto';
import { MessageDialogBoxComponent } from '../../../components/message-dialog-box/message-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';

@Component({
  selector: 'app-update-user-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
  ],
  templateUrl: './update-user-view.component.html',
  styleUrl: './update-user-view.component.scss'
})
export class UpdateUserViewComponent {
  public userId: any;
  public addressId: any;
  public cityId: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, public cookieManagerService: CookieManagerService) {
    this.route.params.subscribe(params => this.userId = params['id']);
  }

  userCpfFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  nameFormControl = new FormControl('', [Validators.required]);
  userNameFormControl = new FormControl('', [Validators.required]);
  userEmailFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  worksForCnpjFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  streetFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  numberFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  complementFormControl = new FormControl({ value: '', disabled: true });
  zipCodeFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  cityFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  provinceFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  provinceAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  countryFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  countryAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);

  ngOnInit(): void {
    this.carregarDadosUser();
  }

  carregarDadosUser(): void {
    const serviceRoute: string = '/users/';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}${this.userId}`,
      { headers }
    ).subscribe((responsebody) => {
      this.userCpfFormControl.setValue(((responsebody as any).data as any).cpf)
      this.nameFormControl.setValue(((responsebody as any).data as any).name)
      this.userNameFormControl.setValue(((responsebody as any).data as any).username)
      this.userEmailFormControl.setValue(((responsebody as any).data as any).email)
      this.worksForCnpjFormControl.setValue(((responsebody as any).data as any).worksForCnpj)
      this.streetFormControl.setValue(((responsebody as any).data as any).address.street)
      this.numberFormControl.setValue(((responsebody as any).data as any).address.number)
      this.complementFormControl.setValue(((responsebody as any).data as any).address.complement)
      this.zipCodeFormControl.setValue(((responsebody as any).data as any).address.zipCode)
      this.cityFormControl.setValue(((responsebody as any).data as any).address.city.name)
      this.provinceFormControl.setValue(((responsebody as any).data as any).address.city.province.name)
      this.provinceAcronymFormControl.setValue(((responsebody as any).data as any).address.city.province.acronym)
      this.countryFormControl.setValue(((responsebody as any).data as any).address.city.province.country.name)
      this.countryAcronymFormControl.setValue(((responsebody as any).data as any).address.city.province.country.acronym)
      this.addressId = ((responsebody as any).data as any).addressId
      this.cityId = ((responsebody as any).data as any).address.city.id
    });
  }

  updateUserActionButton() {
    const serviceRoute: string = '/users';

    const country = new CountryDto(
      this.countryFormControl.value ?? '',
      this.countryAcronymFormControl.value ?? ''
    );

    const province = new ProvinceDto(
      this.provinceFormControl.value ?? '',
      this.provinceAcronymFormControl.value ?? '',
      country ?? ''
    );

    const city = new CityDto(
      province ?? '',
      this.cityFormControl.value ?? ''
    );

    const address = new AddressDto(
      this.streetFormControl.value ?? '',
      this.numberFormControl.value ?? '',
      this.complementFormControl.value ?? '',
      this.zipCodeFormControl.value ?? '',
      city ?? ''
    )
    address.cityId = this.cityId;

    const user = new UpdateUserDto(
      this.userId ?? '',
      this.userCpfFormControl.value ?? '',
      this.nameFormControl.value ?? '',
      this.userNameFormControl.value ?? '',
      this.userEmailFormControl.value ?? '',
      this.worksForCnpjFormControl.value ?? '',
      this.addressId ?? '',
      address
    );

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.patch<object>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      user, { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Usuário atualizado com sucesso", "Fechar", { duration: 5000 });
        this.router.navigate(['/users/list'])
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }
}
