<div class="main">

  <div class="main-content" style="background-color: #fff8f5">
    <div class="container">
      <p class="my-display-medium">Ainda não é cadastrado?</p>
      <p class="my-title-large">
        Esta é uma área privada. Solicite seu acesso ao administrador responsável.
      </p>
    </div>
  </div>

  <div class="main-content" style="position: relative">
    <div class="header">
      <div class="acura-logo-extended">
        <app-extended-logo></app-extended-logo>
      </div>
      <div class="title">
        <p class="mat-headline-3">SLX Cloud</p>
      </div>
    </div>
    <div class="form">
      <form class="login-form" (keyup.enter)="loginActionButton()">
        <mat-form-field class="login-form-field" style="padding-bottom: 16px" appearance="outline">
          <mat-label>Usuário</mat-label>
          <input type="text" matInput [formControl]="usernameFormControl" placeholder="Digite o seu usuário" />
          <mat-error *ngIf="usernameFormControl.hasError('required')">
            O campo de usuário é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field class="login-form-field" appearance="outline">
          <mat-label>Senha</mat-label>
          <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordFormControl">
          <button type="button" mat-icon-button matSuffix (click)="hidePasswordEvent($event)"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="passwordFormControl.hasError('required')">
            O campo de senha é obrigatório
          </mat-error>
          <mat-error *ngIf="passwordFormControl.hasError('minlength')">
            A senha deve conter no mínimo 8 caracteres
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="footer">
      <button mat-flat-button color="primary" class="action-button" (click)="loginActionButton()"
        [disabled]="(loginButtonDisabled) && (!usernameFormControl.valid || !passwordFormControl.valid)">
        LOGIN
      </button>
    </div>
  </div>
</div>